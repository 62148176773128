
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ViewMixin from "@/mixins/Crud/ViewMixin";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { BaseDataCustomer } from "@/config/Modules";

const CustomerModule = namespace(BaseDataCustomer.store);

@Component({
  components: { DefaultLayout },
  mixins: [ViewMixin],
})
export default class CustomerView extends Vue {
  //private name = "BaseDataCustomerView";

  protected resource = "/customer";
  protected descriptionField = "email";
  protected redirectRouteNameOnDeleteSuccess = "BaseDataCustomerList";

  @CustomerModule.Action("del")
  protected removeAction: any;

  protected onAdd(): void {
    this.$router.push({ name: "BaseDataCustomerCreate" });
  }
}
